import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import TopNavBar from '../containers/TopNavBar';
import DashBoard from '../containers/DashBoard';
import NotFound from '../containers/NotFound';
import AdminDashboard from '../Admin/AdminDashboard';
import { Spin } from 'antd';
import Renunciation from '../Pages/Renunciation/Renunciation';

const EmploymentVisa = lazy(() => import('../Pages/EmploymentVisas/EmploymentVisa'));
const TouristVisa = lazy(() => import('../Pages/TouristVisa/TouristaVisa'));
const EntryVisa = lazy(() => import('../Pages/EntryVisas/EntryVisa'));
const BusinessVisa = lazy(() => import('../Pages/BusinessVisas/BusinessVisa'));
const ConferenceVisa = lazy(() => import('../Pages/Conference/ConferenceVisa'));
const PriceCards = lazy(() => import('../Pages/PriceDisplay/PriceCards'));
const Passport = lazy(() => import('../Pages/Passports/Passport'));
const Oci = lazy(() => import('../Pages/OCIVisa/Oci'));

const AppRoutes = () => {
    return (
        <Suspense fallback={<Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }} />}>

            <TopNavBar />
            <Routes>
                <Route path="/" element={<DashBoard />} />              
                <Route path="/adminDashboard" element={<AdminDashboard element={<Oci />} />} />
                <Route path="/oci" element={<ProtectedRoute element={<Oci />} />} />
                <Route path="/conferencevisa" element={<ProtectedRoute element={<ConferenceVisa />} />} />
                <Route path="/businessvisa" element={<ProtectedRoute element={<BusinessVisa />} />} />
                <Route path="/entryvisa" element={<ProtectedRoute element={<EntryVisa />} />} />
                <Route path="/touristvisa" element={<ProtectedRoute element={<TouristVisa />} />} />
                <Route path="/employment" element={<ProtectedRoute element={<EmploymentVisa />} />} />
                <Route path="/passport" element={<ProtectedRoute element={<Passport />} />} />
                <Route path="/renunciation" element={<ProtectedRoute element={<Renunciation />} />} />
                <Route path="/price" element={<ProtectedRoute element={<PriceCards />} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default AppRoutes;
