import React, { useState } from "react";
import on from "../EmploymentVisas/employmentvisa.css";
import {
  InputField,
  SignUpText,
  InfoTextBlock,
} from "../../helpers/formHelpers";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { DatePickerField } from "../../helpers/DatePickerField";
import api from "../../services/api";

function Renunciation() {
  const [show, setShow] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const handleShow = () => setShow(true);

  const touristVisaUpdate = async (data) => {
    try {
      const response = await api.post("/endpoint", data); // Replace with your API endpoint
      // setData(response.data);
    } catch (err) {
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <section className="visa-main-banner">
        <div className="row">
          <div className="col-md-12 col-12">
            <h3>Renunciation Information Form</h3>
          </div>
        </div>
      </section>
      <section className="visa-page-main">
        <div className="container">
          <form className="renunciation-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-4">
              <div className="col-md-4">
                <label for="usNationalorNon-UsNational" className="form-label">
                  Are you US National or Non-US National
                </label>
                <InputField
                  id="usNationalorNon-UsNational"
                  register={register}
                  errors={errors}
                  validation={{
                    required: "US National or Non-US National is required",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label for="currentNationality" className="form-label">
                  Current Nationality
                </label>
                <InputField
                  id="currentNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Current Nationality is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="previousNationality" className="form-label">
                  Previous Nationality
                </label>
                <InputField
                  id="previousNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Previous Nationality is required" }}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Have you ever held Indian Citizenship/Passport ?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Have you renounced your Indian Citizenship ?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Have you applied for renunciation of your indian Citizenship ?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Do you have your most recent indian passport in your possession ?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Indian Passport has stamp does it have a cancelled stamp due to acquiring foreign Nationality?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label for="dob" className="form-label">
                Indian Passport has stamp due to any reason other than acquiring foreign Nationality?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Indian Passport Only Cancellation?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label for="dob" className="form-label">
                Have you used the indian passport for travel that originated 3 months after the naturalization date?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Was your indian passport renewed/reissued after acquiring foreign passport ?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label for="dob" className="form-label">
                Have you availed any consular defined miscellaneous services on your retained/ un-surrendered passport after acquiring citizenship of another country?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Is your indian passport lost or damaged or surrendered to foreign Govt. ?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label for="dob" className="form-label">
                Were your Grandfather/ Grandmother (Paternal/Maternal) Pakistan National or belonged to Pakistan held area?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label for="dob" className="form-label">
                  Have you Ever changed your name ?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label for="dob" className="form-label">
                How will you submit your application?
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Walking
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Shipping
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-3">
            <div className="col-md-4">
                <label htmlFor="modeOfPayment" className="form-label">
                  Mode of Payment to Ckgs ?
                </label>
                <select
                  className={`form-select ${errors.gender ? "is-invalid" : ""}`}
                  id="modeOfPayment"
                  {...register("modeOfPayment", {
                    required: "Mode of Payment is required",
                  })}
                >
                  <option value="male">Credit</option>
                  <option value="female">Money Order Pay Online</option>
                  <option value="other">Other</option>
                </select>
                {errors.modeOfPayment && (
                  <div className="invalid-feedback">
                    {errors.modeOfPayment.message}
                  </div>
                )}
              </div>
              <DatePickerField
                name="dateOfNaturalization"
                control={control}
                errors={errors}
                label="Date of Naturalization"
              />
             
              <DatePickerField
                name="dateOfTravel"
                control={control}
                errors={errors}
                label="Date of Travel"
              />
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label for="firstName" className="form-label">
                  First Name
                </label>
                <InputField
                  id="firstName"
                  register={register}
                  errors={errors}
                  validation={{ required: "First Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="lastName" className="form-label">
                  Last Name
                </label>
                <InputField
                  id="lastName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Last Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="gender" className="form-label">
                  Gender
                </label>
                <select
                  className={`form-select ${errors.gender ? "is-invalid" : ""}`}
                  id="gender"
                  {...register("gender", {
                    required: "Gender is required",
                  })}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {errors.gender && (
                  <div className="invalid-feedback">
                    {errors.gender.message}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-4">
                <label htmlFor="maritalStatus" className="form-label">
                  Marital Status
                </label>
                <select
                  className={`form-select ${
                    errors.maritalStatus ? "is-invalid" : ""
                  }`}
                  id="maritalStatus"
                  {...register("maritalStatus", {
                    required: "Marital status is required",
                  })}
                >
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                </select>
                {errors.maritalStatus && (
                  <div className="invalid-feedback">
                    {errors.maritalStatus.message}
                  </div>
                )}
              </div>
              <DatePickerField
                name="dateOfBirth"
                control={control}
                errors={errors}
                label="Date of Birth"
              />
               <div className="col-md-4">
                <label for="placeOfBirth" className="form-label">
                  Place of Birth
                </label>
                <InputField
                  id="placeOfBirth"
                  register={register}
                  errors={errors}
                  validation={{ required: " Place of Birth is required" }}
                />
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-4">
                    <label for="city" className="form-label">
                      City
                    </label>
                    <InputField
                      id="city"
                      register={register}
                      errors={errors}
                      validation={{ required: "city is required" }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select
                      className={`form-select ${
                        errors.state ? "is-invalid" : ""
                      }`}
                      id="state"
                      {...register("state", {
                        required: "State selection is required",
                      })}
                    >
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                    </select>
                    {errors.state && (
                      <div className="invalid-feedback">
                        {errors.state.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                <label for="country" className="form-label">
                  Country
                </label>
                <InputField
                  id="country"
                  register={register}
                  errors={errors}
                  validation={{ required: "country is required" }}
                />
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-4">
                <label for="email" className="form-label">
                  Email Address
                </label>
                <InputField
                  id="email"
                  register={register}
                  errors={errors}
                  validation={{ required: "Email Address is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="telephone" className="form-label">
                  Telephone Number
                </label>
                <InputField
                  id="tele"
                  register={register}
                  errors={errors}
                  validation={{ required: "Telephone Number is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="usaPassportNumber" className="form-label">
                 USA Passport Number
                </label>
                <InputField
                  id="usaPassportNumber"
                  register={register}
                  errors={errors}
                  validation={{ required: "USA Passport Number is required" }}
                />
              </div>
            </div>
            <div className="row mb-4">
             <DatePickerField
                name="usaPassportdateOfIssue"
                control={control}
                errors={errors}
                label="USA Passport Date of Issue"
              />
               <DatePickerField
                name="usaPassportdateOfExpiry"
                control={control}
                errors={errors}
                label="USA Passport Date of Expiration"
              />
               <div className="col-md-4">
                <label for="usaPassportplaceOfIssue" className="form-label">
                 USA Passport Place of Issue
                </label>
                <InputField
                  id="usaPassportplaceOfIssue"
                  register={register}
                  errors={errors}
                  validation={{ required: " Place of Issue is required" }}
                />
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-4">
                <label for="indianPassportNumber" className="form-label">
                 Indian Passport Number
                </label>
                <InputField
                  id="indianPassportNumber"
                  register={register}
                  errors={errors}
                  validation={{ required: "Indian Passport Number is required" }}
                />
              </div>
            <DatePickerField
                name="indianPassportdateOfIssue"
                control={control}
                errors={errors}
                label=" Indian Passport Date of Issue"
              />
               <DatePickerField
                name="indianPassportdateOfExpiry"
                control={control}
                errors={errors}
                label=" Indian Passport Date of Expiration"
              />
                 
            </div>
            <div className="row mb-4">
            <div className="col-md-4">
                <label for="indianPassportplaceOfIssue" className="form-label">
                  Indian Passport Place of Issue
                </label>
                <InputField
                  id="indianPassportplaceOfIssue"
                  register={register}
                  errors={errors}
                  validation={{ required: "Place of Issue is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="presentAddressinUsa" className="form-label">
                  Present Address in USA
                </label>
                <textarea
                  className={`form-control ${
                    errors.presentAddressinUsa ? "is-invalid" : ""
                  }`}
                  id="presentAddressinUsa"
                  rows="1"
                  {...register("presentAddressinUsa", {
                    required: "Present address is required",
                  })}
                ></textarea>
                {errors.presentAddress && (
                  <div className="invalid-feedback">
                    {errors.presentAddressinUsa.message}
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <label htmlFor="presentAddressinIndia" className="form-label">
                  Present Address in India
                </label>
                <textarea
                  className={`form-control ${
                    errors.presentAddressinIndia ? "is-invalid" : ""
                  }`}
                  id="presentAddressinIndia"
                  rows="1"
                  {...register("presentAddressinIndia", {
                    required: "Present address is required",
                  })}
                ></textarea>
                {errors.presentAddressinIndia && (
                  <div className="invalid-feedback">
                    {errors.presentAddressinIndia.message}
                  </div>
                )}
              </div>
            </div>
           
            


            <div className="row mb-3">
            <div className="col-md-4">
                <label for="fatherName" className="form-label">
                  Father Name
                </label>
                <InputField
                  id="fatherName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Father Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="fatherCurrentNationality" className="form-label">
                  Father Current Nationality
                </label>
                <InputField
                  id="fatherCurrentNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Father Current Nationality is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="fatherPreviousNationality" className="form-label">
                  Father Previous Nationality
                </label>
                <InputField
                  id="fatherPreviousNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Father Previous Nationality is required" }}
                />
              </div>
            
            </div>
            <div className="row mb-4">
            <div className="col-md-4">
                <label for="fatherPlaceOfBirth" className="form-label">
                  Father's Place of Birth
                </label>
                <InputField
                  id="fatherBirthPlace"
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Father's Place of Birth is required",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label for="motherName" className="form-label">
                  Mother Name
                </label>
                <InputField
                  id="motherName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Mother Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="motherCurrentNationality" className="form-label">
                  Mother Current Nationality
                </label>
                <InputField
                  id="motherCurrentNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Mother Current Nationality is required" }}
                />
              </div>
            </div>

            <div className="row mb-3">
            <div className="col-md-4">
                <label for="motherPreviousNationality" className="form-label">
                  Mother Previous Nationality
                </label>
                <InputField
                  id="motherPreviousNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Mother Previous Nationality is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="motherPlaceOfBirth" className="form-label">
                  Mother's Place of Birth
                </label>
                <InputField
                  id="montherBirthPlace"
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Mother's Place of Birth is required",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label for="spouseName" className="form-label">
                  Spouse Name
                </label>
                <InputField
                  id="spouseName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Spouse Name is required" }}
                />
              </div>
            </div>
            <div className="row mb-3">
           
              <div className="col-md-4">
                <label for="spouseCurrentNationality" className="form-label">
                Spouse Current Nationality
                </label>
                <InputField
                  id="spouseCurrentNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Spouse Current Nationality is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="spousePreviousNationality" className="form-label">
                Spouse Previous Nationality
                </label>
                <InputField
                  id="spousePreviousNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Spouse Previous Nationality is required" }}
                />
              </div>
              <div className="col-md-4">
                <label for="spousePlaceOfBirth" className="form-label">
                  Spouse Place of Birth
                </label>
                <InputField
                  id="spousePlaceOfBirth"
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Spouse Place of Birth is required",
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-center mb-5 mt-4">
                <button
                  type="submit"
                  onClick={handleShow}
                  className="btn btn-primary preview-btn"
                >
                  Preview
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-1 submit-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Renunciation;
