import React, { useState } from "react";
import "./employmentvisa.css";
import { InputField } from "../../helpers/formHelpers";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import EmpoymentVisaView from "./EmpoymentVisaView";
import moment from "moment/moment";
import { Alert } from "@mui/material";
import { Button, Modal, Spinner } from "react-bootstrap";
import { json, useNavigate } from "react-router-dom";
import { DatePickerField } from "../../helpers/DatePickerField";
import { formatDate } from "../../helpers/utils";
import { useAuth } from "../../Context/AuthProvider";

function EmploymentVisa() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [state, setState] = useState({
    show: false,
    viewData: null,
    isSubmit: false,
    isPreview: false,
    isAPiSucess: false,
    isLoader: false,
    isServerError: "",
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

const isMaritalStatus =  watch("maritalStatus");

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      isPreview: false,
      isSubmit: false,
    }));
  };

  const handlePreview = () => {
    setState((prev) => ({
      ...prev,
      isSubmit: false,
      show: false,
      isPreview: true,
    }));
  };

  const employmentVisaUpdate = async (payload) => {
    try {
      const { data } = await api.post("/visa/createEmployeeVisa", payload);
      console.log("response", data);
      if (data?.success) {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: true,
          isLoader: false,
        }));
        navigate("/price");
      }
    } catch (err) {
      if (err?.response && err.response?.data && err.response.data?.message) {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: false,
          isLoader: false,
          isServerError: err?.response?.data.message,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: false,
          isLoader: false,
          isServerError: err.message,
        }));
      }
    }
  };

  const onSubmit = (data) => {
    console.log(JSON.stringify(data))
    setState((prev) => ({ ...prev, viewData: data, isServerError: "" }));
    if (!state.isPreview) {
      setState((prev) => ({ ...prev, isSubmit: true, isServerError: null }));
    }
  };

  const handleSave = (data) => {
    const payload = {
      userId: user?.email || '',
      userDetailsDTO: {
        firstName: data.firstName,
        lastName: data.lastName,
        otherName: data.otherName,
        dateOfBirth: formatDate(data.dateOfBirth),
        placeOfBirth: data.placeOfBirth,
        currentNationality: data.currentNationality,
        passportNumber: data.passportNumber,
        dateOfIssue: formatDate(data.dateOfIssue),
        dateOfExpiry: formatDate(data.dateOfExpiry),
        gender: data.gender,
        religion: data.religion,
        maritalStatus: data.maritalStatus,
      },
      spouseInformation: isMaritalStatus
        ? {
            firstName: data.spousFirstName,
            lastName: data.spousLastName,
            dob: data.spouseDateOfBirth,
            placeOfBirth: data.spousPlaceOfBirth,
            city: data.spousCity,
            state: data.spousState,
            country: data.spousCountry,
            nationality: data.spousNationality,
          }
        : undefined,
      currentOccupation: {
        employerDetails: data.employerDetails,
        usaAddress: data.usaAddress,
        telephoneNumber: data.telephoneNumber,
        email: data.email,
        fatherFirstName: data.fatherFirstName,
        fatherLastName: data.fatherLastName,
        fatherPlaceOfBirth: data.fatherPlaceOfBirth,
        fatherNationality: data.fatherNationality,
        motherFirstName: data.motherFirstName,
        motherLastName: data.motherLastName,
        motherPlaceOfBirth: data.motherPlaceOfBirth,
        motherNationality: data.motherNationality,
        periodOfVisa: data.periodOfVisa,
        noOfEntries: data.noOfEntries,
        expectedDOJ: formatDate(data.expectedDOJ),
        portOfArrival: data.portOfArrival,
        portOfDeparture: data.portOfDeparture,
      },
      referenceFromIndia: {
        name: data.refInName,
        address: data.refIndAddress,
        phone: data.refInPhone,
      },
      referenceFromUsa: {
        firstName: data.refUsfName,
        lastName: data.refUslName,
        address: data.refUsAddress,
        phone: data.refUsphone,
      },
      companyDetails: {
        companyName: data.indCompanyName,
        address: data.indCompanyAddress,
        phone: data.indPhone,
        email: data.indEmail,
      },
    };
    setState((prev) => ({ ...prev, isLoader: true, isServerError: null }));
    employmentVisaUpdate(payload);
  };

  return (
    <>
      <section className="visa-main-banner">
        <div className="row">
          <div className="col-md-12 col-12">
            <h3>Employment Visa Information Form</h3>
          </div>
        </div>
      </section>
      <section className="visa-page-main">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <InputField
                  id="firstName"
                  register={register}
                  errors={errors}
                  validation={{ required: "First Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <InputField
                  id="lastName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Last Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="otherName" className="form-label">
                  Other Name
                </label>
                <InputField
                  id="otherName"
                  register={register}
                  errors={errors}
                  validation={{ required: "OtherName is required" }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <DatePickerField
                name="dateOfBirth"
                control={control}
                errors={errors}
                label="Date of Birth"
              />
              <div className="col-md-4">
                <label htmlFor="placeOfBirth" className="form-label">
                  Place of Birth
                </label>
                <InputField
                  id="placeOfBirth"
                  register={register}
                  errors={errors}
                  validation={{ required: " Place of Birth is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="nationality" className="form-label">
                  Current Nationality
                </label>
                <InputField
                  id="currentNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Current Nationality is required" }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="passportNumber" className="form-label">
                  Passport Number
                </label>
                <InputField
                  id="passportNumber"
                  register={register}
                  errors={errors}
                  validation={{ required: "Passport Number is required" }}
                />
              </div>
              <DatePickerField
                name="dateOfIssue"
                control={control}
                errors={errors}
                label="Date of Issue"
              />
              <DatePickerField
                name="dateOfExpiry"
                minDate={new Date()}
                control={control}
                errors={errors}
                label="Date of Expiration"
              />
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="gender" className="form-label">
                  Gender
                </label>
                <select
                  className={`form-select ${errors.gender ? "is-invalid" : ""}`}
                  id="gender"
                  {...register("gender", {
                    required: "Gender is required",
                  })}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {errors.gender && (
                  <div className="invalid-feedback">
                    {errors.gender.message}
                  </div>
                )}
              </div>

              <div className="col-md-4">
                <label htmlFor="religion" className="form-label">
                  Religion
                </label>
                <InputField
                  id="religion"
                  register={register}
                  errors={errors}
                  validation={{ required: "Religion is required" }}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="maritalStatus" className="form-label">
                  Marital Status
                </label>
                <select
                  className={`form-select ${errors.maritalStatus ? "is-invalid" : ""
                    }`}
                  id="maritalStatus"
                  {...register("maritalStatus", {
                    required: "Marital status is required",
                  })}
                >
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                </select>
                {errors.maritalStatus && (
                  <div className="invalid-feedback">
                    {errors.maritalStatus.message}
                  </div>
                )}
              </div>
            </div>
            {isMaritalStatus === "married" && (
              <>
            <div className="row current-occu">
              <div className="col-md-12 text-center mb-4 mt-4">
                <h4>Spouse Information</h4>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-4">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <InputField
                  id="spousFirstName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Spouse First Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <InputField
                  id="spousLastName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Spouse Last Name is required" }}
                />
              </div>

              <DatePickerField
                name="spouseDateOfBirth"
                control={control}
                errors={errors}
                label=" Date of Birth"
              />
            </div>
            <div className="row mb-4">
              <div className="col-md-4">
                <label htmlFor="placeOfBirth" className="form-label">
                  Place of Birth
                </label>
                <InputField
                  id="spousPlaceOfBirth"
                  register={register}
                  errors={errors}
                  validation={{ required: " Place of Birth is required" }}
                />
              </div>
              <div className="col-md-4 ">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <InputField
                  id="spousCity"
                  register={register}
                  errors={errors}
                  validation={{ required: "city is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <select
                  className={`form-select ${errors.state ? "is-invalid" : ""}`}
                  id="spousState"
                  {...register("state", {
                    required: "State selection is required",
                  })}
                >
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                </select>
                {errors.state && (
                  <div className="invalid-feedback">{errors.state.message}</div>
                )}
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-4">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <InputField
                  id="spousCountry"
                  register={register}
                  errors={errors}
                  validation={{ required: "country is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="nationality" className="form-label">
                  Nationality
                </label>
                <InputField
                  id="spousNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Nationality is required" }}
                />
              </div>
            </div>
</>)}
              <div className="row current-occu">
              <div className="col-md-12 text-center mb-4 mt-4">
                <h4>Current Occupation</h4>
              </div>
               </div>

            <div className="row mb-4">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="employerName" className="form-label">
                      Name of Employer
                    </label>
                    <InputField
                      id="employerDetails"
                      register={register}
                      errors={errors}
                      validation={{ required: "Name of Employer is required" }}
                    />
                  </div>
                  <div className="col-md-12 namofemp">
                    <label
                      htmlFor="educationQualification"
                      className="form-label"
                    >
                      Education Qualification
                    </label>
                    <select
                      className={`form-select ${errors.educationQualification ? "is-invalid" : ""
                        }`}
                      id="educationQualification"
                      {...register("educationQualification", {
                        required: "Education qualification is required",
                      })}
                    >
                      <option value="highschool">High School</option>
                      <option value="bachelor">Bachelor's Degree</option>
                      <option value="master">Master's Degree</option>
                      <option value="phd">PhD</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.educationQualification && (
                      <div className="invalid-feedback">
                        {errors.educationQualification.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="employerAddress" className="form-label">
                  Employer Address
                </label>
                <textarea
                  className={`form-control ${errors.employerAddress ? "is-invalid" : ""
                    }`}
                  id="employerAddress"
                  rows="4"
                  {...register("employerAddress", {
                    required: "Employer address is required",
                  })}
                ></textarea>
                {errors.employerAddress && (
                  <div className="invalid-feedback">
                    {errors.employerAddress.message}
                  </div>
                )}
              </div>

              <div className="col-md-4">
                <label htmlFor="presentAddress" className="form-label">
                  Present Address in USA
                </label>
                <textarea
                  className={`form-control ${errors.presentAddress ? "is-invalid" : ""
                    }`}
                  id="presentAddress"
                  rows="4"
                  {...register("presentAddress", {
                    required: "Present address is required",
                  })}
                ></textarea>
                {errors.presentAddress && (
                  <div className="invalid-feedback">
                    {errors.presentAddress.message}
                  </div>
                )}
              </div>
            </div>
          

            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <InputField
                  id="city"
                  register={register}
                  errors={errors}
                  validation={{ required: "City is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <select
                  className={`form-select ${errors.state ? "is-invalid" : ""}`}
                  id="state"
                  {...register("state", {
                    required: "State selection is required",
                  })}
                >
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                </select>
                {errors.state && (
                  <div className="invalid-feedback">{errors.state.message}</div>
                )}
              </div>
              <div className="col-md-4">
                <label htmlFor="zipCode" className="form-label">
                  ZIP Code
                </label>
                <InputField
                  id="zipCode"
                  register={register}
                  errors={errors}
                  validation={{ required: "ZIP Code is required" }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="telephone" className="form-label">
                  Telephone Number
                </label>
                <InputField
                  id="telephoneNumber"
                  type="number"
                  register={register}
                  errors={errors}
                  validation={{ required: "Telephone Number is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <InputField
                  id="email"
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Email Address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="fatherFirstName" className="form-label">
                  Father's First Name
                </label>
                <InputField
                  id="fatherFirstName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Father's First Name is required" }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="fatherLastName" className="form-label">
                  Father's Last Name
                </label>
                <InputField
                  id="fatherLastName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Father's Last Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="fatherPlaceOfBirth" className="form-label">
                  Father's Place of Birth
                </label>
                <InputField
                  id="fatherPlaceOfBirth"
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Father's Place of Birth is required",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="fatherNationality" className="form-label">
                  Father's Nationality
                </label>
                <InputField
                  id="fatherNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Father's Nationality is required" }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="motherFirstName" className="form-label">
                  Mother's First Name
                </label>
                <InputField
                  id="motherFirstName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Mother's First Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="motherLastName" className="form-label">
                  Mother's Last Name
                </label>
                <InputField
                  id="motherLastName"
                  register={register}
                  errors={errors}
                  validation={{ required: "Mother's Last Name is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="motherPlaceOfBirth" className="form-label">
                  Mother's Place of Birth
                </label>
                <InputField
                  id="motherPlaceOfBirth"
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Mother's Place of Birth is required",
                  }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="motherNationality" className="form-label">
                  Mother's Nationality
                </label>
                <InputField
                  id="motherNationality"
                  register={register}
                  errors={errors}
                  validation={{ required: "Mother's Nationality is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="visaPeriod" className="form-label">
                  Period Of Visa
                </label>
                <InputField
                  id="periodOfVisa"
                  register={register}
                  errors={errors}
                  validation={{ required: "Period Of Visa is required" }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="noOfEntries" className="form-label">
                  Number Of Entries
                </label>
                <InputField
                  register={register}
                  type="number"
                  id="noOfEntries"
                  errors={errors}
                  validation={{ required: "Number Of Entries is required" }}
                />
              </div>
            </div>
            <div className="row mb-4">
              <DatePickerField
                name="expectedDOJ"
                minDate={new Date()}
                control={control}
                errors={errors}
                label="'Expected Date Of Journey is required"
              />
              <div className="col-md-4">
                <label htmlFor="portOfArrival" className="form-label">
                  Port of Arrival
                </label>
                <InputField
                  register={register}
                  id="partOfArrival"
                  errors={errors}
                  validation={{ required: "Port of Arrival is required" }}
                  type="text"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="portOfDeparture" className="form-label">
                  Port of Departure
                </label>
                <InputField
                  register={register}
                  id="partOfDeparture"
                  errors={errors}
                  validation={{ required: "Port of Departure is required" }}
                  type="text"
                />
              </div>
            </div>
            <div className="row current-occu">
              <div className="col-md-12 text-center mb-4 mt-4">
                <h4>Company from India Details</h4>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="indCompanyName" className="form-label">
                      Company Name
                    </label>

                    <InputField
                      register={register}
                      id="indCompanyName"
                      errors={errors}
                      validation={{ required: " Company Nameis required" }}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="indEmail" className="form-label">
                      Email
                    </label>
                    <InputField
                      register={register}
                      id="indEmail"
                      errors={errors}
                      validation={{
                        required: "Email Address is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="indPhone" className="form-label">
                      Phone
                    </label>
                    <InputField
                      register={register}
                      id="indPhone"
                      errors={errors}
                      validation={{ required: "Phone is required" }}
                      type="number"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="indPurposeVisit" className="form-label">
                      Purpose of Visit
                    </label>
                    <InputField
                      register={register}
                      id="indPurposeVisit"
                      errors={errors}
                      validation={{ required: "Purpose of Visit is required" }}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="indCompanyAddress" className="form-label">
                  Address
                </label>
                <textarea
                  className={`form-control ${errors.indCompanyAddress ? "is-invalid" : ""
                    }`}
                  id="indCompanyAddress"
                  rows="4"
                  {...register("indCompanyAddress", {
                    required: "address is required",
                  })}
                ></textarea>
                {errors.address && (
                  <div className="invalid-feedback">
                    {errors.address.message}
                  </div>
                )}
              </div>
            </div>
            <div className="row current-occu">
              <div className="col-md-12 text-center mb-4 mt-4">
                <h4>Company from USA Details</h4>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="usaCompanyName" className="form-label">
                      Company Name
                    </label>
                    <InputField
                      register={register}
                      id="usaCompanyName"
                      errors={errors}
                      validation={{ required: " Company Name is required" }}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="usaEmail" className="form-label">
                      Email
                    </label>
                    <InputField
                      register={register}
                      id="usaEmail"
                      errors={errors}
                      validation={{
                        required: "Email Address is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="usaPhone" className="form-label">
                      Phone
                    </label>
                    <InputField
                      register={register}
                      id="usaPhone"
                      errors={errors}
                      validation={{ required: "Usa  is required" }}
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="usaCompanyAddress" className="form-label">
                  Address
                </label>
                <textarea
                  className={`form-control ${errors.usaCompanyAddress ? "is-invalid" : ""
                    }`}
                  id="usaCompanyAddress"
                  rows="4"
                  {...register("usaCompanyAddress", {
                    required: "address is required",
                  })}
                ></textarea>
                {errors.address && (
                  <div className="invalid-feedback">
                    {errors.address.message}
                  </div>
                )}
              </div>
            </div>
            <div className="row current-occu">
              <div className="col-md-12 text-center mb-4 mt-4">
                <h4>References from india</h4>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="col-md-12 mb-1">
                  <label htmlFor="employerName" className="form-label">
                    Name
                  </label>
                  <InputField
                    id="refInName"
                    register={register}
                    errors={errors}
                    validation={{ required: "Name is required" }}
                  />
                </div>
                <div className="col-md-12 namofemp">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <InputField
                    id="refInPhone"
                      type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: "Phone number is required" }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="refIndAddress" className="form-label">
                  Address
                </label>
                <textarea
                  className={`form-control ${errors.refIndAddress ? "is-invalid" : ""
                    }`}
                  id="refIndAddress"
                  rows="4"
                  {...register("refIndAddress", {
                    required: "address is required",
                  })}
                ></textarea>
                {errors.refIndAddress && (
                  <div className="invalid-feedback">
                    {errors.refIndAddress.message}
                  </div>
                )}
              </div>
            </div>

            <div className="row current-occu">
              <div className="col-md-12 text-center mb-4 mt-4">
                <h4>References from USA</h4>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="employerName" className="form-label">
                      First Name
                    </label>
                    <InputField
                      id="refUsfName"
                      register={register}
                      errors={errors}
                      validation={{ required: "First Name is required" }}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <label htmlFor="refUslName" className="form-label">
                      Last Name
                    </label>
                    <InputField
                      id="refUslName"
                      register={register}
                      errors={errors}
                      validation={{ required: "Last Name is required" }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 namofemp">
                    <label htmlFor="refUsphone" className="form-label">
                      Phone
                    </label>
                    <InputField
                      id="refUsphone"
                        type="number"
                      register={register}
                      errors={errors}
                      validation={{ required: "Phone number is required" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="refUsAddress" className="form-label">
                  Address
                </label>
                <textarea
                  className={`form-control ${errors.refUsAddress ? "is-invalid" : ""
                    }`}
                  id="refUsAddress"
                  rows="4"
                  {...register("refUsAddress", {
                    required: "address is required",
                  })}
                ></textarea>
                {errors.address && (
                  <div className="invalid-feedback">
                    {errors.address.message}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center mb-5 mt-4">
                <button
                  type="submit"
                  onClick={handlePreview}
                  className="btn btn-primary preview-btn"
                >
                  Preview
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-1 submit-btn"
                >
                  Submit
                </button>
              </div>
            </div>
            {state.viewData && (
              <EmpoymentVisaView
                isPrivew={state.isPreview}
                viewData={state.viewData}
                handleClose={handleClose}
                handleSave={()=>handleSave(state.viewData)}
              />
            )}
          </form>
        </div>
      </section>
      <>
        <Modal
          show={state.isSubmit}
          onHide={handleClose}
          className="confirm-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Are you sure want to Submit{" "}
            </Modal.Title>
            {state.isServerError && (
              <Alert variant="filled" severity="error">
                {state.isServerError}
              </Alert>
            )}
          </Modal.Header>
         
          <Modal.Footer className="confirm-button-center">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={state.isLoader}
              onClick={() => handleSave(state.viewData)}
            >
              {state.isLoader ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}

export default EmploymentVisa;
