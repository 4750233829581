import moment from "moment";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const EmpoymentVisaView = ({ viewData, isPrivew, handleClose,handleSave }) => {

  console.log("viewData", viewData);
  
  return (
    <Modal className="modal-lg form-view" show={isPrivew} onHide={handleClose}>
      <Modal.Header className="preview-popup" closeButton>
        <Modal.Title>Employment Visa form Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="visa-page-main">
          <div className="container">
            <form>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="firstName" className="form-label">
                    First Name
                  </label>
                  <p>{viewData.firstName}</p>
                </div>
                <div className="col-md-4">
                  <label for="lastName" className="form-label">
                    Last Name
                  </label>
                  <p>{viewData.lastName}</p>
                </div>
                <div className="col-md-4">
                  <label for="otherName" className="form-label">
                    Other Name
                  </label>
                  <p>{viewData.otherName}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="dob" className="form-label">
                    Date of Birth
                  </label>
                  <p>{moment(viewData?.dateOfBirth).format("YYYY/MM/DD")}</p>
                </div>
                <div className="col-md-4">
                  <label for="placeOfBirth" className="form-label">
                    Place of Birth
                  </label>
                  <p>{viewData.placeOfBirth}</p>
                </div>
                <div className="col-md-4">
                  <label for="nationality" className="form-label">
                    Current Nationality
                  </label>
                  <p>{viewData.currentNationality}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="passportNumber" className="form-label">
                    Passport Number
                  </label>
                  <p>{viewData.passportNumber}</p>
                </div>
                <div className="col-md-4">
                  <label for="dateOfIssue" className="form-label">
                    Date of Issue
                  </label>
                  <p>{moment(viewData?.dateOfIssue).format("YYYY/MM/DD")}</p>
                </div>
                <div className="col-md-4">
                  <label for="dateOfExpiration" className="form-label">
                    Date of Expiration
                  </label>
                  <p>{moment(viewData?.dateOfExpiry).format("YYYY/MM/DD")}</p>

                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="gender" className="form-label">
                    Gender
                  </label>
                  <p>{viewData.gender}</p>
                </div>

                <div className="col-md-4">
                  <label for="religion" className="form-label">
                    Religion
                  </label>
                  <p>{viewData.religion}</p>
                </div>

                <div className="col-md-4">
                  <label for="maritalStatus" className="form-label">
                    Marital Status
                  </label>
                  <p>{viewData.maritalStatus}</p>
                </div>
              </div>

              <div classNameName="row current-occu">
                <div classNameName="col-md-12 text-center mb-4 mt-4">
                  <h4>Current Occupation</h4>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-4">
                  <div classNameName="row">
                    <div classNameName="col-md-12 mb-1">
                      <label for="employerName" className="form-label">
                        Name of Employer
                      </label>
                      <p>{viewData.employerDetails}</p>
                    </div>
                    <div classNameName="col-md-12 ">
                      <label
                        for="educationQualification"
                        className="form-label"
                      >
                        Education Qualification
                      </label>
                      <p>{viewData.qualification}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <label for="employerAddress" className="form-label">
                    Employer Address
                  </label>
                  <p>{viewData.employerDetails}</p>
                </div>
                <div className="col-md-4">
                  <label for="presentAddress" className="form-label">
                    Present Address in USA
                  </label>
                  <p>{viewData.usaAddress}</p>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="city" className="form-label">
                    City
                  </label>
                  <p></p>
                </div>
                <div className="col-md-4">
                  <label for="state" className="form-label">
                    State
                  </label>
                  <p></p>
                </div>
                <div className="col-md-4">
                  <label for="zipCode" className="form-label">
                    ZIP Code
                  </label>
                  <p></p>{" "}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="telephone" className="form-label">
                    Telephone Number
                  </label>
                  <p>{viewData.telephoneNumber}</p>
                </div>
                <div className="col-md-4">
                  <label for="email" className="form-label">
                    Email Address
                  </label>
                  <p>{viewData.email}</p>
                </div>
                <div className="col-md-4">
                  <label for="fatherFirstName" className="form-label">
                    Father's First Name
                  </label>
                  <p>{viewData.fatherFirstName}</p>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="fatherLastName" className="form-label">
                    Father's Last Name
                  </label>
                  <p>{viewData.fatherLastName}</p>
                </div>
                <div className="col-md-4">
                  <label for="fatherPlaceOfBirth" className="form-label">
                    Father's Place of Birth
                  </label>
                  <p>{viewData.fatherPlaceOfBirth}</p>
                </div>
                <div className="col-md-4">
                  <label for="fatherNationality" className="form-label">
                    Father's Nationality
                  </label>
                  <p>{viewData.fatherNationality}</p>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="motherFirstName" className="form-label">
                    Mother's First Name
                  </label>
                  <p>{viewData.motherFirstName}</p>
                </div>
                <div className="col-md-4">
                  <label for="motherLastName" className="form-label">
                    Mother's Last Name
                  </label>
                  <p>{viewData.motherLastName}</p>
                </div>
                <div className="col-md-4">
                  <label for="motherPlaceOfBirth" className="form-label">
                    Mother's Place of Birth
                  </label>
                  <p>{viewData.motherPlaceOfBirth}</p>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-4">
                  <label for="motherNationality" className="form-label">
                    Mother's Nationality
                  </label>
                  <p>{viewData.motherNationality}</p>
                </div>
                <div className="col-md-4">
                  <label for="visaPeriod" className="form-label">
                    Period Of Visa
                  </label>
                  <p>{viewData.periodOfVisa}</p>
                </div>
                <div className="col-md-4">
                  <label for="noOfEntries" className="form-label">
                    Number Of Entries
                  </label>
                  <p>{viewData.noOfEntries}</p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4">
                  <label for="expectedDateOfJourney" className="form-label">
                    Expected Date Of Journey
                  </label>
                  <p>{moment(viewData?.expectedDOJ).format("YYYY/MM/DD")}</p>
                </div>
                <div className="col-md-4">
                  <label for="portOfArrival" className="form-label">
                    Port of Arrival
                  </label>
                  <p>{viewData.portOfArrival}</p>
                </div>
                <div className="col-md-4">
                  <label for="portOfDeparture" className="form-label">
                    Port of Departure
                  </label>
                  <p>{viewData.portOfDeparture}</p>
                </div>
              </div>
              <div className="row current-occu">
                <div className="col-md-12 text-center mb-4 mt-4">
                  <h4>Company from India Details</h4>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <label for="indCompanyName" className="form-label">
                        Company Name
                      </label>
                        <p>{viewData.indCompanyName}</p>
                    </div>
                    <div className="col-md-6 ">
                      <label for="indEmail" className="form-label">
                        Email
                      </label>
                      <p>{viewData.indEmail}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label for="indPhone" className="form-label">
                        Phone
                      </label>
                      <p>{viewData.indPhone}</p>
                    </div>
                    <div className="col-md-6">
                      <label for="indPurposeVisit" className="form-label">
                        Purpose of Visit
                      </label>
                      <p></p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <label for="indCompanyAddress" className="form-label">
                    Address
                  </label>
                  <p>{viewData.indCompanyAddress}</p>
                 
                </div>
              </div>
              <div className="row current-occu">
                <div className="col-md-12 text-center mb-4 mt-4">
                  <h4>Company from USA Details</h4>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <label for="usaCompanyName" className="form-label">
                        Company Name
                      </label>
                     <p></p>
                    </div>
                    <div className="col-md-6 ">
                      <label for="usaEmail" className="form-label">
                        Email
                      </label>
                      <p></p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label for="usaPhone" className="form-label">
                        Phone
                      </label>
                     <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label for="usaCompanyAddress" className="form-label">
                    Address
                  </label>
                 <p></p>
                </div>
              </div>
              <div className="row current-occu">
                <div className="col-md-12 text-center mb-4 mt-4">
                  <h4>References from india</h4>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="col-md-12 mb-1">
                    <label for="employerName" className="form-label">
                      Name
                    </label>
                    <p>{viewData.refInName}</p>
                  </div>
                  <div className="col-md-12 namofemp">
                    <label for="phone" className="form-label">
                      Phone
                    </label>
                    <p>{viewData.refInPhone}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <label for="refIndAddress" className="form-label">
                    Address
                  </label>
                  <p>{viewData.refIndAddress}</p>
                </div>
              </div>

              <div className="row current-occu">
                <div className="col-md-12 text-center mb-4 mt-4">
                  <h4>References from USA</h4>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <label for="employerName" className="form-label">
                        First Name
                      </label>
                      <p>{viewData.refUsfName}</p>
                    </div>

                    <div className="col-md-6 ">
                      <label for="refUslName" className="form-label">
                        Last Name
                      </label>
                      <p>{viewData.refUslName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 namofemp">
                      <label for="refUsphone" className="form-label">
                        Phone
                      </label>
                      <p>{viewData.refUsphone}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <label for="refUsAddress" className="form-label">
                    Address
                  </label>
                  <p>{viewData.refUsAddress}</p>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
        <Button variant="primary" onClick={handleSave}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmpoymentVisaView;
