import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import '../Pages/EmploymentVisas/employmentvisa.css'
import dayjs from "dayjs";
export const DatePickerField = ({ name, control,minDate=null, errors, label }) => (
    <div className="col-md-4">
        <label htmlFor={name} className="form-label">{label}</label>
        <div className="form-group">
            <Controller
                name={name}
                control={control}
                defaultValue={null}
                rules={{ required: `${label} is required` }}
                render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={field.value}
                            minDate={dayjs(minDate)}

                            onChange={(newValue) => field.onChange(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!errors[name]}
                                    helperText={errors[name]?.message}
                                />
                            )}
                        />
                    </LocalizationProvider>
                )}
            />
            {errors[name] && <p style={{ color: '#dc3545' }}>{errors[name]?.message}</p>}
        </div>
    </div>
);