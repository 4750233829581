import React, { useState } from 'react'
import { Table, Button, Upload } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';

  
const initialData = [
    {
      key: '1',
      username: 'John Doe',
      email: 'john.doe@example.com',
      phoneNumber: '123-456-7890',
      visaType: 'Tourist Visa',
      uploadedFile: null,
    },
    {
      key: '2',
      username: 'Jane Smith',
      email: 'jane.smith@example.com',
      phoneNumber: '098-765-4321',
      visaType: 'Business Visa',
      uploadedFile: null,
    },
  ];
  
  // Define columns
  const columns = (handleFileUpload, handleDownload, handleSubmit) => [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
     
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      
    },
    {
      title: 'Visa Type',
      dataIndex: 'visaType',
      key: 'visaType',
     
    },
    {
      title: 'Upload File',
      dataIndex: 'uploadFile',
      key: 'uploadFile',
      render: (text, record) => (
        <Upload
          beforeUpload={(file) => handleFileUpload(file, record.key)}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      ),
    },
    {
      title: 'Download File',
      dataIndex: 'downloadFile',
      key: 'downloadFile',
      render: (text, record) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => handleDownload(record)}
        //   disabled={!record.uploadedFile}
        >
          Download
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'submit',
      key: 'submit',
      render: (text, record) => (
        <Button type="primary" onClick={() => handleSubmit(record)}>
          Submit
        </Button>
      ),
    },
  ];
function InProgressBookings() {


    const [dataSource, setDataSource] = useState(initialData);
    // http://localhost:8080/file?userId=66e5b1ec592e2665cec8dc0c
    // http://localhost:8080/file/all
 

    
    const handleFileUpload = (file, key) => {
      const updatedData = dataSource.map((record) => {
        if (record.key === key) {
          return { ...record, uploadedFile: file };
        }
        return record;
      });
      setDataSource(updatedData);
      return false; // Prevent default upload behavior
    };
  
    // File download handler
    const handleDownload = (record) => {
      if (record.uploadedFile) {
        const url = URL.createObjectURL(record.uploadedFile);
        const link = document.createElement('a');
        link.href = url;
        link.download = record.uploadedFile.name;
        link.click();
      }
    };
  
    // Submit handler
    const handleSubmit = (record) => {
      console.log('Submitted data:', record);
      // You can trigger API calls or other actions here
    };
  return (
    <Table
      dataSource={dataSource}
      columns={columns(handleFileUpload, handleDownload, handleSubmit)}
      pagination={false} 
      rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')} // Apply even/odd row classes
      className="custom-header"
    />
  )
}

export default InProgressBookings