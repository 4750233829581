import React, { useState } from "react";
import "../App.css";
import google from "../assets/images/google.png";
import facebook from "../assets/images/facebook.png";
import {
  faFacebookF,
  faLinkedinIn,
  faSquareInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faPhone,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Login from "../Pages/Login";
import EmploymentVisa from "../Pages/EmploymentVisas/EmploymentVisa";
import { Link } from "react-router-dom";

const DashBoard = () => {

  return (<div>
    welcome
  </div>)

};

export default DashBoard;
