import React from 'react';
import { useAuth } from '../Context/AuthProvider';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faRightToBracket } from "@fortawesome/free-solid-svg-icons";

function Logout({handleShow}) {
  const { logout, user } = useAuth();

  return (
   <>
   {user ? <Dropdown align="end">
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        <FontAwesomeIcon icon={faUser} /> User
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/account">Account</Dropdown.Item>
                        <Dropdown.Item href="#/dashboard">
                          Dashboard
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#/logout"  onClick={()=>logout()}>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> :
                      <Button
                        className="sign-in"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <FontAwesomeIcon icon={faRightToBracket} /> Sign In
                      </Button>
                    }</>
  );
}

export default Logout;
