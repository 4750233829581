import React from 'react';
import RouterApp from './router';
import { AuthProvider } from './Context/AuthProvider';

function App() {

  return (
    <div>
      <AuthProvider>
      <RouterApp />
      </AuthProvider>
    </div>
  );
}

export default App;
