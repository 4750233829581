import React, { useEffect, useState } from 'react'
import api from '../services/api';

function PendingBookings() {
  const [state, setState] = useState({
    show: false,
    viewData: null,
    isSubmit: false,
    isPreview: false,
    isAPiSucess: false,
    isLoader: false,
    isServerError: "",
  });

  const pendingList = async () => {
    console.log('==================')
    try {
      const { data } = await api.get("/file/all");
      console.log("response=======>", data);
      if (data?.success) {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: true,
          isLoader: false,
        }));
      }
    } catch (err) {
      if (err?.response && err.response?.data && err.response.data?.message) {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: false,
          isLoader: false,
          isServerError: err?.response?.data.message,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: false,
          isLoader: false,
          isServerError: err.message,
        }));
      }
    }
  };

  useEffect(() => {
    pendingList()
    }, [])

  return (
    <div>PendingBookings</div>
  )
}

export default PendingBookings