
import React, { useEffect, useState } from 'react'
import InProgressBookings from './InProgressBookings';
import PendingBookings from './PendingBookings';
import CompletedBookings from './CompletedBookings';
import { Tab, Tabs, Container, Row, Col, Badge } from 'react-bootstrap';
import './AdminDashboard.css'
import api from '../services/api';

const AdminDashboard = () => {
  const [state, setState] = useState({
    show: false,
    viewData: null,
    isSubmit: false,
    isPreview: false,
    isAPiSucess: false,
    isLoader: false,
    isServerError: "",
  });

  const employmentVisaUpdate = async () => {
    console.log('==================')
    try {
      const { data } = await api.get("/file/all");
      console.log("response=======>", data);
      if (data?.success) {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: true,
          isLoader: false,
        }));
      }
    } catch (err) {
      if (err?.response && err.response?.data && err.response.data?.message) {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: false,
          isLoader: false,
          isServerError: err?.response?.data.message,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          show: false,
          isAPiSucess: false,
          isLoader: false,
          isServerError: err.message,
        }));
      }
    }
  };

  useEffect(() => {
    employmentVisaUpdate()

    }, [])
 
    // progressCount,pendingCount,completedCount
  return (
    <>
    <div className="container admin-container">
    
   <div className='rounded-tabs'> 
     <div className='sub-rounded'>
        <p><span className='progr'>Progress</span> 
        <Badge bg="secondary">20</Badge>
         </p>
     </div>
     <div className='sub-rounded'>
        <p><span className='cmplted'>Completd</span>  <span className="count">20</span></p>
     </div>
     <div className='sub-rounded'>
        <p><span className='pending'>Pending</span> <span className="count">20</span></p>
        
     </div>
    </div>
  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
 
      <Tab eventKey="home" title="In-progress Bookings" className='home'>
        <div className=""><InProgressBookings /></div>
      </Tab>
      <Tab eventKey="profile" title="Completed Bookings">
        <div className=""><CompletedBookings/></div>
      </Tab>
      <Tab eventKey="contact" title="Pending Bookings">
        <div className=""><PendingBookings /></div>
      </Tab>

     
     
    </Tabs>
   
  </div>
  </>
  )
}

export default AdminDashboard