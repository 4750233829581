import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from 'react-hook-form';
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faBell, faPassport } from "@fortawesome/free-solid-svg-icons";

import { InputField, SignUpText, InfoTextBlock } from "../helpers/formHelpers";
import api from "../services/api";

import "../Pages/Login.css";
import { Alert } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../Context/AuthProvider";

function Login({ show, handleClose }) {
  const [isMember, setIsMember] = useState(false);
  const [loader, setLoader] = useState(false);
  const [serverError, setServerError] = useState(null)

  const { login } = useAuth();
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const toggleMember = (isUpdate) => {
    setIsMember(isUpdate);
    reset();
  };

  useEffect(() => {
    setInterval(() => {
      setServerError(null)
    }, 5000);

  }, [serverError])


  const handleApiRequest = async (endpoint, payload) => {
    setLoader(true)
    try {
      const response = await api.post(endpoint, payload);
      if (response) {
        setLoader(false)
        handleClose();
      }
    } catch (err) {
      if (err?.response && err.response?.data && err.response.data?.message) {
        setServerError(err?.response?.data.message);
      } else {
        setServerError(err.message);
      }
      setLoader(false)
    }
  };
  const handleApilogin = async (endpoint, payload) => {
    try {
      setLoader(true)
      const queryString = new URLSearchParams(payload).toString();
      const urlWithParams = `${endpoint}?${queryString}`;
      console.log("urlWithParams", urlWithParams)
      const {data} = await api.post(urlWithParams);
      if (data?.success) {
        setLoader(false)
        login(data.data);
        handleClose();
      }
    } catch (err) {
      if (err?.response && err.response?.data && err.response.data?.message) {
        setServerError(err?.response?.data.message);
      } else {
        setServerError(err.message);
      }
      setLoader(false)
    }
  }
  const onSubmit = (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };

    if (isMember) {
      payload.firstName = data.firstName;
      payload.lastName = data.lastName;
      payload.mobile = data.mobile;
      handleApiRequest('/user/createUser', payload);
    } else {
      handleApilogin('/user/login', payload);
    }
  };

  const renderSignUpForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        {serverError && <Alert variant="filled" severity="error">
          {serverError}
        </Alert>}
        {loader && <div class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center">
          <div role="status">
            <Spinner animation="border" variant="success" />
          </div>
        </div>}
        <div className="col-md-6 sign-up-form">

          <div className="form-body">
            <InputField
              id="firstName"
              label="First Name"
              className="form-floating"
              register={register}
              errors={errors}
              validation={{ required: 'First Name is required' }}
            />
            <InputField
              id="lastName"
              label="Last Name"
              className="form-floating"
              register={register}
              errors={errors}
              validation={{ required: 'Last Name is required' }}
            />
            <InputField
              id="email"
              label="Email address"
              className="form-floating"
              register={register}
              errors={errors}
              validation={{
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
            />
            {/* <InputField
              id="tel"
              label="Phone Number"
              className="form-floating"
              type="tel"
              register={register}
              errors={errors}
              validation={{ required: 'Phone number is required' }}
            /> */}
            <InputField
              id="password"
              label="Password"
              type="password"
              className="form-floating"
              register={register}
              errors={errors}
              validation={{
                required: 'Password is required',
                minLength: { value: 6, message: 'Password must be at least 6 characters long' },
              }}
            />
            <InputField
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              className="form-floating"
              register={register}
              errors={errors}
              validation={{
                required: 'Please confirm your password',
                validate: (value) =>
                  value === password || 'Passwords do not match',
              }}
            />
            {!errors.confirmPassword && confirmPassword && confirmPassword === password && (
              <p style={{ color: 'green' }}>Passwords match</p>
            )}
            <div className="form-check mb-3">
              <input className="form-check-input" type="checkbox" id="termsCheck" />
              <label className="form-check-label" htmlFor="termsCheck">
                By signing up you accept our <a href="#">Terms</a> and <a href="#">Privacy Policy</a>.
              </label>
            </div>
            <button type="submit" className="btn btn-primary btn-signup w-100 mb-3">
              Sign Up
            </button>
          </div>
        </div>
        <div className="col-md-6 sign-up-text">
          <div className="txt-main">
            <div className="text-body">
              <h3>Sign up with Click2Visa</h3>
            </div>
            <SignUpText isMember={isMember} toggleMember={toggleMember} />
            <InfoTextBlock icon={faBell} title="Faster Visa Filling" description="Sign up into click2visa sign up into click2visa" />
            <InfoTextBlock icon={faCcVisa} title="Faster Visa Filling" description="Sign up into click2visa sign up into click2visa Sign up into click2visa" />
            <InfoTextBlock icon={faPassport} title="Faster Visa Filling" description="Sign up into click2visa sign up into click2visa Sign up" />
          </div>
        </div>
      </div>
    </form>
  );


  const renderLoginForm = () => (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="text-body">
            <h3>Sign in with Click2Visa</h3>
          </div>
          <SignUpText isMember={isMember} toggleMember={toggleMember} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          {serverError && <Alert variant="filled" severity="error">
            {serverError}
          </Alert>}
          {loader && <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center spinner-center">
            <Spinner animation="border" variant="success" />
          </div>}
          <div className="row">
            <div className="col-md-12">
              <div className="form-body">
                <InputField
                  id="email"
                  label="Email address"
                  type="email"
                  className="form-floating"
                  register={register}
                  errors={errors}
                  validation={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  }}
                />
                <InputField
                  id="password"
                  label="Password"
                  type="password"
                  className="form-floating"
                  register={register}
                  errors={errors}
                  validation={{ required: 'Password is required' }}
                />
                <div className="forgotpass">
                  <a href="#">Forgot your password?</a>
                </div>
                <button type="submit" className="btn btn-primary btn-signup w-100 mb-3">
                  Login
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </>
  );

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`modal-lg ${isMember ? 'modal-signup-btn' : 'modal-md modal-login'}`}
      show={show}
      onHide={() => { handleClose(); setServerError(false); setLoader(false) }}
    >
      {isMember ? renderSignUpForm() : renderLoginForm()}
    </Modal >
  );
}

export default Login;
