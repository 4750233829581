import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import {
  faFacebookF,
  faLinkedinIn,
  faSquareInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Login from "../Pages/Login";
import { Link } from "react-router-dom";

import Logout from "./Logout";

const TopNavBar = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    if (pathName == "/login") {
      setShow(true);
    }
  }, [pathName]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <header>
        <div className="header-top">
          <div className="container">
            <div className="top-main">
              <div className="social-media">
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faSquareInstagram} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faXTwitter} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </li>
                </ul>
              </div>
              <div className="email-phone">
                <ul>
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} /> info@click2visa.com
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPhone} /> +91 9666648446
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
              <a className="navbar-brand" href="/">
                Logo
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleMenu}
                aria-controls="navbarNav"
                aria-expanded={isMenuOpen ? "true" : "false"}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse ${isMenuOpen ? "show animate__animated animate__fadeInDown" : ""
                  }`}
                id="navbarNav"
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown1"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Visas
                    </a>
                    <ul
                      className="dropdown-menu animate__animated animate__fadeInDown"
                      aria-labelledby="navbarDropdown1"
                    >
                      <Link to="/employment">
                        <a className="dropdown-item" to="employment">
                          Employment Visa
                        </a>
                      </Link>
                      <Link to="/touristvisa">
                        <li>
                          <a className="dropdown-item" href="#action2">
                            Tourist visa
                          </a>
                        </li>
                      </Link>
                      <Link to="/entryvisa">
                        <li>
                          <a className="dropdown-item" href="#action2">
                            Entry visa
                          </a>
                        </li>
                      </Link>

                      <Link to="/businessvisa">
                        <li>
                          <a className="dropdown-item" href="#action2">
                            Business visa
                          </a>
                        </li>
                      </Link>
                      <Link to="/conferencevisa">
                        <li>
                          <a className="dropdown-item" href="#action2">
                            Conference visa
                          </a>
                        </li>
                      </Link>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown2"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      E-Visa
                    </a>
                    <ul
                      className="dropdown-menu animate__animated animate__fadeInDown"
                      aria-labelledby="navbarDropdown2"
                    >
                      <li>
                        <a className="dropdown-item" href="#action4">
                          Action 4
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#action5">
                          Action 5
                        </a>
                      </li>
                      <li className="dropdown-submenu">
                        <a className="dropdown-item dropdown-toggle" href="#">
                          Submenu 2
                        </a>
                        <ul className="dropdown-menu animate__animated animate__fadeInRight">
                          <li>
                            <a className="dropdown-item" href="#subaction3">
                              Sub Action 3
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#subaction4">
                              Sub Action 4
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown1"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      OCI & Renunciation
                    </a>
                    <ul
                      className="dropdown-menu animate__animated animate__fadeInDown"
                      aria-labelledby="navbarDropdown1"
                    >
                      <Link to="/oci">
                        <a href="/oci" className="dropdown-item" to="employment">
                          OCI
                        </a>
                      </Link>
                      <Link to="#">
                        <li>
                          <a className="dropdown-item" href="#action2">
                            Renunciation
                          </a>
                        </li>
                      </Link>
                      <Link to="#">
                        <li>
                          <a className="dropdown-item" href="#action2">
                            Miscellaneous
                          </a>
                        </li>
                      </Link>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown1"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Passport
                    </a>
                    <ul
                      className="dropdown-menu animate__animated animate__fadeInDown"
                      aria-labelledby="navbarDropdown1"
                    >
                      <Link to="/passport">
                        <a href="/passport" className="dropdown-item" to="employment">
                          Indian Passport
                        </a>
                      </Link>
                      <Link to="#">
                        <li>
                          <a className="dropdown-item" href="#action2">
                            USA Passport
                          </a>
                        </li>
                      </Link>
                    </ul>
                  </li>

                  <li className="nav-item user-btn">

<Logout handleShow={handleShow}  />
                    {show && <Login show={show} handleClose={handleClose} />}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default TopNavBar;
